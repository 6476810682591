<script lang="ts" setup>
import { uniqueId } from 'lodash-es'
import { computed, ref, useSlots } from 'vue'

const props = withDefaults(
  defineProps<{
    type?: string
    darkMode?: true | false | 'auto'
    colorScheme?: OzInputColorScheme
    valid?: 'yes' | 'no' | 'unknown'
    disabled?: boolean
    required?: boolean
    testId?: string
    validationMessage?: string
    textAlign?: 'start' | 'end' | 'center'
    noHorizontalPadding?: boolean
    sizePreset?: OzInputSizePreset
    xBorder?: boolean
    shouldTruncateText?: boolean
    value?: string // when binded by v-model, this prop is provided in vue 2
    modelValue?: string // when binded by v-model, this prop is provided in vue 3
    ariaDescribedby?: string
  }>(),
  {
    type: 'text',
    darkMode: 'auto',
    colorScheme: OzInputColorScheme.Translucent,
    valid: 'unknown',
    disabled: false,
    required: false,
    testId: '',
    validationMessage: '',
    textAlign: undefined,
    noHorizontalPadding: false,
    sizePreset: OzInputSizePreset.H40px,
    xBorder: true,
    shouldTruncateText: false,
    value: undefined,
    modelValue: undefined,
    ariaDescribedby: undefined,
  },
)

const isFocused = ref(false)

const internalValue = computed({
  get: () => props.modelValue || props.value,
  set: (val) => {
    emit('update:modelValue', val)
  },
})

const inputElement = ref<HTMLInputElement | null>(null)
const validationMessageId = uniqueId('validationMessageId')
const isValid = computed(() => props.valid !== 'no')
const xValidationMessage = computed(() => !isValid.value && !!props.validationMessage?.length)

const slots = useSlots()
const hasExtraContent = computed(() => slots.extraContent)

const ariaDescribedbyValue = computed<string>(() => {
  if (props.ariaDescribedby) return `${validationMessageId} ${props.ariaDescribedby}`
  return validationMessageId
})

const backgroundClasses = computed(() => {
  if (props.colorScheme === OzInputColorScheme.Secondary) {
    if (isFocused.value) {
      return {
        'bg-light-ui-100': props.darkMode === false,
        'bg-dark-ui-100': props.darkMode === true,
        'bg-light-ui-100 dark:bg-dark-ui-100': props.darkMode === 'auto',
      }
    } else {
      return {
        'bg-grey-100': props.darkMode === false,
        'bg-grey-850': props.darkMode === true,
        'bg-grey-100 dark:bg-grey-850': props.darkMode === 'auto',
      }
    }
  } else if (props.colorScheme === OzInputColorScheme.Secondary200) {
    if (isFocused.value) {
      return {
        'bg-light-ui-100': props.darkMode === false,
        'bg-dark-ui-100': props.darkMode === true,
        'bg-light-ui-100 dark:bg-dark-ui-100': props.darkMode === 'auto',
      }
    } else {
      return {
        'bg-light-ui-200': props.darkMode === false,
        'bg-dark-ui-200': props.darkMode === true,
        'bg-light-ui-200 dark:bg-dark-ui-200': props.darkMode === 'auto',
      }
    }
  }

  return ''
})

const borderClasses = computed(() => {
  if (!props.xBorder) return ''

  if (props.colorScheme === OzInputColorScheme.Translucent) {
    const commonClasses = {
      'border-1': props.sizePreset === OzInputSizePreset.H32px || props.sizePreset === OzInputSizePreset.H20px,
      'border-2': props.sizePreset === OzInputSizePreset.H40px,
      'border-solid': true,
    }

    if (!isValid.value) return { ...commonClasses, 'border-danger-100': true }

    if (props.disabled)
      return {
        ...commonClasses,
        'border-grey-300': props.darkMode === false,
        'border-white-disabled': props.darkMode === true,
        'border-grey-300 dark:border-white-disabled': props.darkMode === 'auto',
      }

    if (isFocused.value)
      return {
        ...commonClasses,
        'border-grape-500': props.darkMode === false,
        'border-canary-500': props.darkMode === true,
        'border-grape-500 dark:border-canary-500': props.darkMode === 'auto',
      }

    return {
      ...commonClasses,
      'border-dark-ui-100': props.darkMode === false,
      'border-light-ui-100': props.darkMode === true,
      'border-dark-ui-100 dark:border-light-ui-100': props.darkMode === 'auto',
    }
  } else if (props.colorScheme === OzInputColorScheme.Secondary) {
    const commonClasses = {
      'border-1': props.sizePreset === OzInputSizePreset.H32px || props.sizePreset === OzInputSizePreset.H20px,
      'border-2': props.sizePreset === OzInputSizePreset.H40px,
      'border-solid': true,
    }

    if (!isValid.value) return { ...commonClasses, 'border-danger-100': true }

    if (isFocused.value)
      return {
        ...commonClasses,
        'border-grape-500': props.darkMode === false,
        'border-canary-500': props.darkMode === true,
        'border-grape-500 dark:border-canary-500': props.darkMode === 'auto',
      }

    return {
      ...commonClasses,
      'border-transparent': true,
    }
  } else if (props.colorScheme === OzInputColorScheme.Secondary200) {
    const commonClasses = {
      'border-2': true,
      'border-solid': true,
    }

    if (isFocused.value)
      return {
        ...commonClasses,
        'border-grape-500': props.darkMode === false,
        'border-canary-500': props.darkMode === true,
        'border-grape-500 dark:border-canary-500': props.darkMode === 'auto',
      }

    return {
      ...commonClasses,
      'border-transparent': true,
    }
  } else {
    return ''
  }
})

const emit = defineEmits<{
  (event: 'focusin', value: FocusEvent): void
  (event: 'focusout', value: FocusEvent): void
  (event: 'blur', value: FocusEvent): void
  (event: 'input', value: InputEvent): void // vue 2 v-model, refer to https://v3-migration.vuejs.org/breaking-changes/v-model.html
  (event: 'update:modelValue', value: string): void // vue 3 v-model, refer to https://vuejs.org/guide/components/v-model.html
}>()

// in vue-2, v-model is binded to the value prop and an inputEvent is expected to be emitted on input.
const onInput = (event: Event) => {
  emit('input', event as InputEvent)
}

const focusin = (event: FocusEvent) => {
  isFocused.value = true
  // Emit the focusin event again after we are done with our internal handling
  // So the user of the component can listen to it if they want
  emit('focusin', event)
}

const focusout = (event: FocusEvent) => {
  isFocused.value = false
  // Emit the focusout event again after we are done with our internal handling
  // So the user of the component can listen to it if they want
  emit('focusout', event)
}

const blur = (event: FocusEvent) => {
  // Emit the blur event again after we are done with our internal handling
  // So the user of the component can listen to it if they want
  emit('blur', event)
}

const focusInput = () => {
  inputElement.value?.focus()
}

const selectInput = () => {
  inputElement.value?.select()
}

const blurInput = () => {
  inputElement.value?.blur()
}

defineExpose({
  focusInput,
  selectInput,
  blurInput,
})
</script>
<script lang="ts">
export enum OzInputSizePreset {
  H20px = 'H20px',
  H32px = 'H32px',
  H40px = 'H40px',
}

export enum OzInputColorScheme {
  Translucent = 'Translucent',
  Secondary = 'Secondary',
  Secondary200 = 'Secondary200',
}

export default {
  inheritAttrs: false,
}
</script>

<template>
  <div
    :class="[
      'transition',
      'flex',
      'flex-1',
      'flex-row',
      'items-center',
      'box-border',
      'relative',
      backgroundClasses,
      borderClasses,
      sizePreset === OzInputSizePreset.H20px && [
        'rounded',
        'h-5',
        slots.startAdornment ? 'ps-0' : 'ps-1',
        slots.endAdornment ? 'pe-0' : 'pe-1',
      ],
      sizePreset === OzInputSizePreset.H32px && [
        'rounded-xl',
        'h-8',
        slots.startAdornment || noHorizontalPadding ? 'ps-0' : 'ps-2',
        slots.endAdornment || noHorizontalPadding ? 'pe-0' : 'pe-2',
      ],
      sizePreset === OzInputSizePreset.H40px && [
        'rounded-2xl',
        'h-10',
        slots.startAdornment || noHorizontalPadding ? 'ps-0' : 'ps-3.5',
        slots.endAdornment || noHorizontalPadding ? 'pe-0' : 'pe-2.5',
      ],
      'font-sans',
    ]"
  >
    <!-- @slot adornment is at the beginning of the input -->
    <slot name="startAdornment"></slot>

    <!-- We have to add "!" to make color and text tailwind classes important!
         so they won't be overidden by the scss stylesheet (_textfield.scss)
         TODO: remove those scss stylesheets -->
    <!-- eslint-disable vue/no-deprecated-dollar-listeners-api, TODO: we still need the $listeners for vue 2, can remove in vue 3 -->
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <input
      ref="inputElement"
      v-bind="$attrs"
      v-model="internalValue"
      :data-pw="testId"
      :data-testid="testId"
      :aria-invalid="xValidationMessage"
      :aria-errormessage="validationMessageId"
      :aria-describedby="ariaDescribedbyValue"
      :aria-required="required || undefined"
      :class="[
        'oz-input',
        {
          '!input-autofill': darkMode === false,
          '!dark:input-autofill-dark': darkMode === true,
          '!input-autofill dark:!input-autofill-dark': darkMode === 'auto',
        },
        {
          '!placeholder-dark-text-300': darkMode === false,
          '!placeholder-light-text-300': darkMode === true,
          '!placeholder-dark-text-300 dark:!placeholder-light-text-300': darkMode === 'auto',
        },
        {
          '!text-input-body-small': sizePreset === OzInputSizePreset.H32px || sizePreset === OzInputSizePreset.H20px,
          '!text-body-posts': sizePreset === OzInputSizePreset.H40px,
        },
        {
          '!text-dark-text-100': darkMode === false,
          '!text-light-text-100': darkMode === true,
          '!text-dark-text-100 dark:!text-light-text-100': darkMode === 'auto',
        },
        hasExtraContent ? 'input-with-extra-content' : 'input-without-extra-content',
        disabled && {
          '!placeholder-dark-text-400': darkMode === false,
          '!placeholder-light-text-400': darkMode === true,
          '!placeholder-dark-text-400 dark:!placeholder-light-text-400': darkMode === 'auto',
        },
        disabled && 'pointer-events-none',
        {
          'text-start': textAlign === 'start',
          'text-end': textAlign === 'end',
          'text-center': textAlign === 'center',
        },
        type === 'datetime-local' && {
          'datetime-picker-auto': darkMode === 'auto',
          'datetime-picker-dark': darkMode === true,
          'datetime-picker-light': darkMode === false,
          // on iOS mobile, the datetime picker text is rendered in the center by default, and text-align property doesn't work on it.
          // so this is a hack to ensure that the input text is aligned based on the textAlign prop.
          'text-last-start': true,
        },
        type === 'number' && 'number-input-no-spinner',
        shouldTruncateText && 'truncate',
      ]"
      :type="type"
      @input="onInput"
      @focusin="focusin"
      @focusout="focusout"
      @blur="blur"
      v-on="{ ...$listeners }"
    />
    <!-- eslint-enable vue/no-deprecated-dollar-listeners-api -->

    <!-- @slot adornment is at the end of the input -->
    <slot name="endAdornment"></slot>

    <span
      :id="validationMessageId"
      aria-live="polite"
      data-testid="validationMessage"
      :class="[
        'absolute',
        'end-2',
        '-bottom-2',
        'transition',
        'text-12-16',
        'text-danger-100',
        'font-semibold',
        'truncate',
        'max-w-[calc(100%-1.5rem)]',
        'px-1',
        {
          'bg-light-ui-100': darkMode === false,
          'bg-dark-ui-100': darkMode === true,
          'bg-light-ui-100 dark:bg-dark-ui-100': darkMode === 'auto',
        },
      ]"
      >{{ xValidationMessage ? validationMessage : null }}</span
    >
  </div>
</template>
<style lang="scss" scoped>
.oz-input {
  @apply flex;
  @apply flex-grow;
  @apply shadow-none;
  @apply focus:shadow-none;
  @apply appearance-none;
  @apply outline-none;
  @apply border-none;
  @apply bg-transparent;
  @apply font-sans;
  @apply w-full;

  // this is just to remove margin and padding added by _textfield.scss
  @apply my-0;
  @apply py-0;

  .input-with-extra-content {
    @apply pe-3;
  }
  .input-without-extra-content {
    @apply pe-3.5;
  }
}

/* Since the datetime picker is quite inconsistent implemently for different browsers,
    below are selectors which are non-standard-features for styling it.
*/

@mixin datetime-picker-text-light {
  &:focus-visible,
  &:active,
  &:focus {
    @apply bg-grape-500;
    @apply text-light-text-100;
    border-radius: 4px;
  }
}

@mixin datetime-picker-text-dark {
  &:focus-visible,
  &:active,
  &:focus {
    @apply bg-canary-500;
    @apply text-dark-text-100;
    border-radius: 4px;
  }
}

// Styles required to hide arrow buttons: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
.number-input-no-spinner::-webkit-outer-spin-button,
.number-input-no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center !important;
}

/* Firefox */
.number-input-no-spinner[type='number'] {
  -moz-appearance: textfield;
  text-align: center !important;
}

.datetime-picker-auto::-webkit-datetime-edit-day-field {
  @apply px-0.75;
  @include datetime-picker-text-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-text-dark;
  }
}

.datetime-picker-light::-webkit-datetime-edit-day-field {
  @apply px-0.75;
  @include datetime-picker-text-light;
}

.datetime-picker-dark::-webkit-datetime-edit-day-field {
  @apply px-0.75;
  @include datetime-picker-text-dark;
}

.datetime-picker-auto::-webkit-datetime-edit-month-field {
  @apply px-0.75;
  @include datetime-picker-text-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-text-dark;
  }
}

.datetime-picker-light::-webkit-datetime-edit-month-field {
  @apply px-0.75;
  @include datetime-picker-text-light;
}

.datetime-picker-dark::-webkit-datetime-edit-month-field {
  @apply px-0.75;
  @include datetime-picker-text-dark;
}

.datetime-picker-auto::-webkit-datetime-edit-year-field {
  @apply px-0.75;
  @include datetime-picker-text-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-text-dark;
  }
}

.datetime-picker-light::-webkit-datetime-edit-year-field {
  @apply px-0.75;
  @include datetime-picker-text-light;
}

.datetime-picker-dark::-webkit-datetime-edit-year-field {
  @apply px-0.75;
  @include datetime-picker-text-dark;
}

.datetime-picker-auto::-webkit-datetime-edit-hour-field {
  @include datetime-picker-text-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-text-dark;
  }
}

.datetime-picker-light::-webkit-datetime-edit-hour-field {
  @include datetime-picker-text-light;
}

.datetime-picker-dark::-webkit-datetime-edit-hour-field {
  @include datetime-picker-text-dark;
}

.datetime-picker-auto::-webkit-datetime-edit-minute-field {
  @include datetime-picker-text-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-text-dark;
  }
}

.datetime-picker-light::-webkit-datetime-edit-minute-field {
  @include datetime-picker-text-light;
}

.datetime-picker-dark::-webkit-datetime-edit-minute-field {
  @include datetime-picker-text-dark;
}

.datetime-picker-auto::-webkit-datetime-edit-second-field {
  @include datetime-picker-text-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-text-dark;
  }
}

.datetime-picker-light::-webkit-datetime-edit-second-field {
  @include datetime-picker-text-light;
}

.datetime-picker-dark::-webkit-datetime-edit-second-field {
  @include datetime-picker-text-dark;
}
.datetime-picker-auto::-webkit-datetime-edit-ampm-field {
  @include datetime-picker-text-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-text-dark;
  }
}

.datetime-picker-light::-webkit-datetime-edit-ampm-field,
.datetime-picker-light::-webkit-datetime-edit-meridiem-field {
  @include datetime-picker-text-light;
}

.datetime-picker-dark::-webkit-datetime-edit-ampm-field,
.datetime-picker-dark::-webkit-datetime-edit-meridiem-field {
  @include datetime-picker-text-dark;
}

@mixin datetime-picker-calendar-picker-icon {
  @apply px-0.75;
  @apply border-solid;
  @apply border-2;
  @apply border-transparent;
  @apply rounded;
  outline: none;
}

// hide the html datetime icon, but position it below the custom datetime-local icon that we visually show to users
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  position: absolute;
  inset-inline-end: 8px;
}

input[type='datetime-local']:before {
  display: block;
  -webkit-mask-image: url('https://padlet.net/icons/svg/oricons/edit_date.svg');
  mask-image: url('https://padlet.net/icons/svg/oricons/edit_date.svg');
  mask-size: contain;
  background-color: currentColor;
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  bottom: 9px;
  inset-inline-end: 10px;
}

@mixin datetime-picker-calendar-picker-icon-light {
  &:focus-visible,
  &:active,
  &:focus {
    @apply border-grape-500 border-2;
  }
  input[type='datetime-local']:before {
    @apply text-dark-text-200;
  }
}

@mixin datetime-picker-calendar-picker-icon-dark {
  &:focus-visible,
  &:active,
  &:focus {
    @apply border-2 border-canary;
  }

  input[type='datetime-local']:before {
    @apply text-light-text-200;
  }
}

.datetime-picker-auto::-webkit-calendar-picker-indicator {
  @include datetime-picker-calendar-picker-icon;
  @include datetime-picker-calendar-picker-icon-light;

  @media (prefers-color-scheme: dark) {
    @include datetime-picker-calendar-picker-icon-dark;
  }
}
.datetime-picker-light::-webkit-calendar-picker-indicator {
  @include datetime-picker-calendar-picker-icon;
  @include datetime-picker-calendar-picker-icon-light;
}

.datetime-picker-dark::-webkit-calendar-picker-indicator {
  @include datetime-picker-calendar-picker-icon;
  @include datetime-picker-calendar-picker-icon-dark;
}
</style>
